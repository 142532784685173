import React from 'react';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { ctx } from '@vl/redata';
import { UnAuthRedirect } from '@uz/unitz-tool-components/AppRedirect';
import Button from '@uz/unitz-components-web/Button';
import Link from '@uz/unitz-components-web/Link';
import { Result, Row, Col } from 'antd';
import gstyles from '@vl/gstyles';

const Index = () => {
  // @TODO: validate missing required input
  return (
    <DIV className="pageContainer">
      <UnAuthRedirect to={ctx.apply('authModel.generateLoginRedirectUrl')}>
        <div className="p-4">
          <div className="mb-3 text-main uppercase font-semibold text-base">
            {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.add_form_title')}
          </div>
          <div className="p-6  bg-white500 rounded-lg shadow-md">
            <Result
              status="success"
              icon={
                <div className="flex justify-center items-center">
                  {gstyles.icons({ name: 'check-success', size: 88 })}
                </div>
              }
              title={
                <div className="mb-4 w-2/3 mx-auto text-brandb500 text-4xl font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.success_title')}
                </div>
              }
              subTitle={
                <div className="text-sub text-base text-center">
                  {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.success_sub_title')}{' '}
                  <span className="font-semibold">
                    {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.course_templates')}
                  </span>
                </div>
              }
            />
            <Row justify="end">
              <Col span={12}>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseTemplates')}>
                      <Button name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                        <span id="button-text">
                          {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.manage_all_templates')}
                        </span>
                      </Button>
                    </Link>
                  </Col>
                  <Col span={12}>
                    <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourseUseTemplate', ctx.get('getTemplateId'))}>
                      <Button type="primary" className="w-full">
                        <span id="button-text">
                          {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Action.start_this_course_now')}
                        </span>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </UnAuthRedirect>
    </DIV>
  );
};

export default displayName(Index);
